<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            Staffs
            <v-spacer></v-spacer>
            <v-download-column-select
              v-if="
                ($auth.isAdmin() ||
                  $auth.loggedInRole().slug === 'administrator') &&
                  form.items.data.length > 0
              "
              :showPDF="false"
              :downloadColumns="dynamicColumns"
              @onDownload="downloadStaff"
            >
              DOWNLOAD STAFF RECORD
            </v-download-column-select>
            <add-button
              class="ml-2"
              @action="
                (form.dialog = true),
                  (same_address = false),
                  $refs.staffInfoForm ? $refs.staffInfoForm.reset() : ''
              "
              permission="staff-create"
              >&nbsp;&nbsp;New Staff
            </add-button>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title mb-0 pb-0">
              <v-flex xs4>
                <v-select
                  label="Filter by role"
                  item-text="name"
                  item-value="id"
                  class="pa-0"
                  outlined
                  dense
                  :items="roles"
                  v-model="members.staff.filter.role"
                  :loading="roleLoading"
                  :disabled="roles.length < 1"
                />
              </v-flex>

              <v-flex xs5>
                <v-text-field
                  hint="Atleast 3 characters"
                  text
                  outlined
                  dense
                  color="primary"
                  class="pa-0"
                  label="Search by Name, Email or Phone"
                  v-model="search"
                  append-icon="search"
                  @input="searchMember"
                />
              </v-flex>

              <transition
                name="animate-css-transition"
                enter-active-class="animated fadeInRight"
                leave-active-class="animated fadeOutRight"
              >
                <v-btn
                  v-if="members.staff.filter.role || search.length"
                  outlined
                  color="primary"
                  text
                  @click="
                    (members.staff.filter.role = ''),
                      (filter = !filter),
                      (search = ''),
                      form.get()
                  "
                  class="mt-n7"
                >
                  Clear
                </v-btn>
              </transition>
            </v-card-title>
          </v-card>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td align="left">{{ index + form.items.meta.from }}</td>
                <td align="left" class="text-xs-left">
                  <v-list-item style="width: 200px">
                    <v-list-item-avatar>
                      <img :src="item.profile_image" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <small
                          ><strong>{{ item.full_name }}</strong></small
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey--text text--lighten-1">
                        <small v-for="(role, ri) in item.roles" :key="ri">
                          <span v-if="ri <= 6"
                            >{{ role.name
                            }}<span v-if="ri < item.roles.length - 1"
                              >,</span
                            ></span
                          >
                        </small>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </td>
                <td class="text-xs-left">
                  <v-icon small color="primary">email</v-icon>
                  <strong v-if="!['teacher'].includes($auth.getRole())">
                    {{ item.email || "N/A" }}
                  </strong>
                  <span v-else>
                    {{
                      item.email_private ? "**********" : item.email || "N/A"
                    }}
                  </span>
                  <br />
                  <v-icon color="warning" small>phone</v-icon>
                  <strong v-if="!['teacher'].includes($auth.getRole())">
                    {{ item.primary_mobile || "N/A" }}
                  </strong>
                  <span v-else>
                    {{
                      item.mobile_private
                        ? "**********"
                        : item.primary_mobile || "N/A"
                    }}
                  </span>
                </td>
                <td class="text-xs-left">
                  {{ item.join_date_format }} <br />
                  <small style="color:#888;"> {{ item.created_at }}</small>
                </td>
                <td class="text-right">
                  <router-link
                    :to="'/staff/' + item.id"
                    style="text-decoration: none;"
                  >
                    <view-button permission="staff-read" />
                  </router-link>
                  <edit-button
                    permission="staff-update"
                    @agree="
                      fetchRoles(),
                        update(item),
                        item.profile_image
                          ? (cropperImg.cropImg = item.profile_image)
                          : ''
                    "
                  />
                  <delete-button
                    permission="staff-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>

      <v-dialog
        @keydown.esc="
          $refs.staffInfoForm.reset(), resetForm(), (form.dialog = false)
        "
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        v-model="form.dialog"
        persistent
        scrollable
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn
              icon
              dark
              @click="
                $refs.staffInfoForm.reset(), resetForm(), (form.dialog = false)
              "
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Add/Update Staff</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="save">Save</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="pb-1" style="padding: 0;">
            <v-form ref="staffInfoForm" v-model="valid" :lazy-validation="lazy">
              <v-timeline class="user_add_form" align-top dense>
                <v-timeline-item color="first-f" small>
                  <v-layout pt-3>
                    <v-flex xs11>
                      <strong>Role</strong>
                      <div class="caption">Select type of staff.</div>
                      <v-combobox
                        class="mt-4"
                        outlined
                        dense
                        multiple
                        v-model="selectedRole"
                        @change="roleChanged"
                        :items="roles"
                        :rules="[(v) => !!v || 'Role Field is required']"
                        item-text="name"
                        chips
                        icon="account_circle"
                        label="Select role."
                      >
                        <template v-slot:selection="role">
                          <v-chip
                            small
                            @input="removeRole(role.item)"
                            @click="markAsPrimaryRole(role.item)"
                            :input-value="role.selected"
                            color="primary"
                            :close="role.item.id !== form.primary_role_id"
                            :key="JSON.stringify(selectedRole.name)"
                            class="v-chip--select-multi"
                          >
                            {{ role.item.name }}
                            <v-icon
                              small
                              v-if="role.item.id === form.primary_role_id"
                            >
                              check_circle
                            </v-icon>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>

                <v-timeline-item color="second-s" small>
                  <v-layout wrap pt-3>
                    <v-flex>
                      <strong>General Info.</strong>
                      <div class="caption">Name, Email etc.</div>
                      <v-flex pt-3 xs11>
                        <v-container fluid style="padding:0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex sm4>
                              <v-text-field
                                :disabled="!selectedRole.length"
                                autocomplete="off"
                                label="First Name*"
                                required
                                class="pa-0"
                                v-model="form.fname"
                                name="name"
                                :rules="[
                                  (v) => !!v || 'First Name Field is required',
                                ]"
                                outlined
                                dense
                                :error-messages="form.errors.get('fname')"
                              />
                            </v-flex>

                            <v-flex sm4>
                              <v-text-field
                                autocomplete="off"
                                label="Middle Name"
                                :disabled="!selectedRole"
                                required
                                class="pa-0"
                                v-model="form.mname"
                                name="rank"
                                outlined
                                dense
                                :error-messages="form.errors.get('mname')"
                              />
                            </v-flex>

                            <v-flex sm4>
                              <v-text-field
                                autocomplete="off"
                                label="Last Name*"
                                required
                                class="pa-0"
                                v-model="form.lname"
                                :disabled="!selectedRole.length"
                                name="rank"
                                outlined
                                dense
                                :error-messages="form.errors.get('lname')"
                                :rules="[
                                  (v) => !!v || 'Last Name Field is required',
                                ]"
                              />
                            </v-flex>

                            <v-flex sm6>
                              <v-text-field
                                prepend-inner-icon="email"
                                label="Email"
                                required
                                class="pa-0"
                                :rules="emailRule"
                                :disabled="!selectedRole.length"
                                v-model="form.email"
                                name="rank"
                                outlined
                                dense
                                :error-messages="form.errors.get('email')"
                              />
                            </v-flex>

                            <v-flex sm6>
                              <v-text-field
                                autocomplete="new-password"
                                required
                                v-model="form.password"
                                :append-icon="
                                  p_show ? 'visibility' : 'visibility_off'
                                "
                                :disabled="!selectedRole.length"
                                :type="p_show ? 'text' : 'password'"
                                :rules="!updateState ? passwordRule : []"
                                name="password"
                                label="Password"
                                hint="At least 8 characters"
                                outlined
                                dense
                                @click:append="p_show = !p_show"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>
                <v-timeline-item color="first-f" small>
                  <v-layout pt-3>
                    <v-flex xs11>
                      <strong>Department</strong>
                      <div class="caption">Select department of staff.</div>
                      <v-combobox
                        class="mt-4"
                        outlined
                        dense
                        multiple
                        v-model="selectedDepartment"
                        @change="departmentChanged"
                        :items="departments"
                        :rules="[(v) => !!v || 'Department Field is required']"
                        item-text="text"
                        chips
                        icon="account_circle"
                        label="Select Department."
                      >
                        <template v-slot:selection="department">
                          <v-chip
                            small
                            :input-value="department.selected"
                            color="primary"
                            :key="JSON.stringify(selectedDepartment.title)"
                            class="v-chip--select-multi"
                          >
                            {{ department.item.text }}
                            <v-icon small>
                              check_circle
                            </v-icon>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>

                <v-timeline-item color="third-t" small>
                  <v-layout pt-3>
                    <v-flex>
                      <strong>Address</strong>
                      <div class="caption">Permanent/Current</div>
                      <v-flex pt-3 xs11>
                        <v-container fluid style="padding:0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex xs12 sm2>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="!selectedRole.length"
                                :items="states"
                                v-model="
                                  staff.addressInfo.data.permanent_state_id
                                "
                                label="Province/State"
                                :rules="[
                                  (v) =>
                                    !!v || 'Province  Name Field is required',
                                ]"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                            <v-flex xs12 sm3>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="
                                  !staff.addressInfo.data.permanent_state_id
                                "
                                :items="permanent_districts"
                                v-model="
                                  staff.addressInfo.data.permanent_district_id
                                "
                                label="District"
                                :rules="[
                                  (v) =>
                                    !!v || 'District Name Field is required',
                                ]"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>

                            <v-flex xs12 sm3>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="
                                  !staff.addressInfo.data.permanent_district_id
                                "
                                :items="permanent_cities"
                                :rules="[
                                  (v) =>
                                    !!v ||
                                    'Municipality/City Name Field is required',
                                ]"
                                v-model="
                                  staff.addressInfo.data.permanent_city_id
                                "
                                label="Municipality/City"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                            <v-flex xs12 sm1>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="
                                  !staff.addressInfo.data.permanent_city_id
                                "
                                :items="ward_count_permanent"
                                :rules="[
                                  (v) => !!v || 'Ward Name Field is required',
                                ]"
                                v-model="
                                  staff.addressInfo.data.permanent_ward_no
                                "
                                label="Ward"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                            <v-flex xs12 sm3 md3>
                              <v-text-field
                                outlined
                                dense
                                label="Street/Tole"
                                :disabled="
                                  !staff.addressInfo.data.permanent_city_id
                                "
                                v-model="
                                  staff.addressInfo.data.permanent_address_line
                                "
                                :rules="[
                                  (v) =>
                                    !!v || 'Street/Tole Name Field is required',
                                ]"
                              />
                            </v-flex>
                            <v-flex pt-0 pb-0 xs12>
                              <v-checkbox
                                label="Mark current address same as permanent"
                                v-model="same_address"
                                :disabled="
                                  !staff.addressInfo.data
                                    .permanent_address_line ||
                                    !staff.addressInfo.data.permanent_city_id
                                "
                              />
                            </v-flex>
                            <v-flex xs12 sm2>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="!selectedRole"
                                :items="states"
                                v-model="
                                  staff.addressInfo.data.temporary_state_id
                                "
                                label="Province/State"
                                :rules="[
                                  (v) =>
                                    !!v ||
                                    'Province/State Name Field is required',
                                ]"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                            <v-flex xs12 sm3>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="
                                  !staff.addressInfo.data.temporary_state_id
                                "
                                :items="temporary_districts"
                                :rules="[
                                  (v) =>
                                    !!v || 'District Name Field is required',
                                ]"
                                v-model="
                                  staff.addressInfo.data.temporary_district_id
                                "
                                label="District"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                            <v-flex xs12 sm3>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="
                                  !staff.addressInfo.data.temporary_district_id
                                "
                                :items="temporary_cities"
                                :rules="[
                                  (v) =>
                                    !!v ||
                                    'Municipality/City Name Field is required',
                                ]"
                                v-model="
                                  staff.addressInfo.data.temporary_city_id
                                "
                                label="Municipality/City"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>

                            <v-flex xs12 sm1>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="
                                  !staff.addressInfo.data.temporary_city_id
                                "
                                :items="ward_count_temporary"
                                :rules="[
                                  (v) => !!v || 'Ward Name Field is required',
                                ]"
                                v-model="
                                  staff.addressInfo.data.temporary_ward_no
                                "
                                label="Ward"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                            <v-flex xs12 sm3 md3>
                              <v-text-field
                                outlined
                                dense
                                label="Street/Tole"
                                :disabled="
                                  !staff.addressInfo.data.temporary_city_id
                                "
                                v-model="
                                  staff.addressInfo.data.temporary_address_line
                                "
                                :rules="[
                                  (v) =>
                                    !!v || 'Street/Tole Name Field is required',
                                ]"
                              />
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>

                <v-timeline-item color="fourth-f" small>
                  <v-layout pt-3>
                    <v-flex>
                      <strong>Other Information</strong>
                      <div class="caption">Phone, Occupation etc.</div>
                      <v-flex pt-3 xs11>
                        <v-container fluid style="padding:0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex sm3>
                              <v-calendar-field
                                id-val="dob"
                                v-model="form.dob"
                                label="Date Of Birth"
                              >
                              </v-calendar-field>
                            </v-flex>
                            <v-flex sm3>
                              <v-calendar-field
                                id-val="join_date"
                                v-model="form.join_date"
                                label="Joined Date"
                              >
                              </v-calendar-field>
                            </v-flex>
                            <v-flex sm3>
                              <v-text-field
                                outlined
                                dense
                                :disabled="!selectedRole.length"
                                v-model="form.pan_no"
                                label="Pan no"
                                :mask="'#########'"
                                prepend-inner-icon="credit_card"
                              ></v-text-field>
                            </v-flex>

                            <v-flex sm3 xs12>
                              <v-autocomplete
                                :items="occupations"
                                outlined
                                dense
                                :disabled="!selectedRole.length"
                                v-model="form.occupation"
                                :error-messages="form.errors.get('occupation')"
                                label="Occupation"
                                prepend-inner-icon="work"
                              ></v-autocomplete>
                            </v-flex>

                            <v-flex sm4>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="!selectedRole.length"
                                v-model="form.gender"
                                :error-messages="form.errors.get('gender')"
                                prepend-inner-icon="face"
                                :items="gender"
                                :rules="[
                                  (v) => !!v || 'Gender Name Field is required',
                                ]"
                                label="Gender"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>

                            <v-flex sm4>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="!selectedRole.length"
                                v-model="form.blood_group"
                                :error-messages="form.errors.get('blood_group')"
                                prepend-inner-icon="add_location"
                                :items="blood"
                                label="Blood group"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>

                            <v-flex sm4>
                              <v-text-field
                                outlined
                                dense
                                v-mask="'###############'"
                                :disabled="!selectedRole.length"
                                v-model="form.residence"
                                :error-messages="form.errors.get('residence')"
                                label="Phone"
                                prepend-inner-icon="perm_phone_msg"
                              ></v-text-field>
                            </v-flex>
                            <v-flex sm4>
                              <v-text-field
                                outlined
                                dense
                                :disabled="!selectedRole.length"
                                v-model="form.primary_mobile"
                                :error-messages="
                                  form.errors.get('primary_mobile')
                                "
                                :rules="staff.validation.primaryMobileRule"
                                label="Mobile"
                                mask="##########"
                                hint="10 digits"
                                counter
                                prepend-inner-icon="mobile_screen_share"
                              ></v-text-field>
                            </v-flex>
                            <v-flex sm4>
                              <v-autocomplete
                                outlined
                                dense
                                :disabled="!selectedRole.length"
                                v-model="form.religion"
                                :error-messages="form.errors.get('religion')"
                                prepend-inner-icon="account_balance"
                                :items="religions"
                                label="Religion"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>

                            <v-flex sm4>
                              <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="place"
                                :disabled="!selectedRole.length"
                                v-model="form.ethnicity"
                                :error-messages="form.errors.get('ethnicity')"
                                :items="ethnicities"
                                label="Ethnicity"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>

                <v-timeline-item color="fifth-fi" small>
                  <v-layout pt-3>
                    <v-flex>
                      <strong>Upload Picture</strong>
                      <div class="caption">Your profile picture.</div>
                      <v-flex pt-3 xs11>
                        <v-container fluid style="padding:0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex xs6>
                              <image-picker
                                :disabled="!selectedRole.length"
                                ui-type="text"
                                label="Select Profile Image"
                                ref="picker"
                                :height="200"
                                :width="200"
                                :ratio="1"
                                @result="cropImage"
                              ></image-picker>

                              <!--<v-text-field :disabled="!selectedRole.length"-->
                              <!--outlined dense-->
                              <!--label="Upload staff's profile picture"-->
                              <!--green v-model="cropperImg.name"-->
                              <!--prepend-inner-icon="add_a_photo"-->
                              <!--@click="onPickFile"-->
                              <!--required/>-->
                              <!--<input-->
                              <!--type="file"-->
                              <!--style="display: none"-->
                              <!--ref="image"-->
                              <!--accept="image/*"-->
                              <!--@change="onFilePicked">-->

                              <v-flex
                                xs10
                                class="text-xs-center text-sm-center text-md-center text-lg-center"
                                v-if="cropperImg.cropImg"
                              >
                                <v-card>
                                  <v-card-text>
                                    <img
                                      :src="cropperImg.cropImg"
                                      height="150"
                                    />
                                    <v-btn
                                      v-if="!updateState"
                                      fab
                                      small
                                      bottom
                                      right
                                      absolute
                                      @click.native.stop="resetImagePicker"
                                      style="z-index: 1"
                                      color="red white--text"
                                    >
                                      <v-icon dark>close</v-icon>
                                    </v-btn>
                                  </v-card-text>
                                </v-card>
                              </v-flex>
                            </v-flex>

                            <v-flex xs6>
                              <v-text-field
                                :disabled="!selectedRole.length"
                                outlined
                                dense
                                label="Upload staff's signature image"
                                v-model="cropperSignature.name"
                                prepend-inner-icon="add_a_photo"
                                @click="onPickFile('signature')"
                                required
                              />
                              <input
                                type="file"
                                style="display: none"
                                ref="signature"
                                accept="image/*"
                                @change="onFilePicked($event, 'signature')"
                              />
                              <v-flex
                                xs10
                                class="text-xs-center text-sm-center text-md-center text-lg-center"
                                v-if="cropperSignature.imgSrc"
                              >
                                <v-card>
                                  <v-card-text>
                                    <img
                                      :src="cropperSignature.imgSrc"
                                      height="150"
                                    />
                                    <v-btn
                                      v-if="!updateState"
                                      fab
                                      small
                                      bottom
                                      right
                                      absolute
                                      @click.native.stop="resetImagePicker"
                                      style="z-index: 1"
                                      color="red white--text"
                                    >
                                      <v-icon dark>close</v-icon>
                                    </v-btn>
                                  </v-card-text>
                                </v-card>
                              </v-flex>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>
              </v-timeline>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import Form from "@/library/Form";
import { getPropertyFromArrayObject } from "@/library/helpers";
import Mixins from "@/library/Mixins";

import statesData from "@/assets/json/states.json";
import districtDataData from "@/assets/json/districts.json";
import cityData from "@/assets/json/cities.json";
import { mapState } from "vuex";
import ImageCompressor from "image-compressor.js";
import { validateDate } from "../../../../../library/helpers";
import "cropperjs/dist/cropper.css";

export default {
  mixins: [Mixins],
  data: () => ({
    downloadColumns: [
      { value: "name", name: "Staff's Name", selected: true },
      { value: "gender", name: "Gender", selected: true },
      { value: "dob", name: "Date of Birth", selected: true },
      { value: "age", name: "Age (Yr)", selected: false },
      { value: "ethnicity", name: "Caste", selected: true, toAdmin: true },
      { value: "mobile", name: "Contact No.", selected: false, toAdmin: true },
      { value: "address", name: "Address", selected: false, toAdmin: true },
      {
        value: "blood_group",
        name: "Blood Group",
        selected: false,
        toAdmin: true,
      },
      { value: "email", name: "Email", selected: false, toAdmin: true },
      { value: "occupation", name: "Occupation", selected: false },
      { value: "join_date", name: "Joined Date", selected: false },
      { value: "role", name: "Role", selected: false },
    ],
    valid: true,
    lazy: false,
    filter: false,
    cropDialog: false,
    imgSrc: "",
    cropImg: "",
    uploadCropped: {},
    cropperImg: {
      cropDialog: false,
      imgSrc: "",
      cropImg: "",
      uploadCropped: {},
      name: null,
    },
    cropperSignature: {
      cropDialog: false,
      imgSrc: "",
      cropImg: "",
      uploadCropped: {},
      name: null,
    },
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
    emailRule: [(v) => !v || /.+@.+/.test(v) || "E-mail must be valid"],
    passwordRule: [
      (v) => !!v || "Password is required",
      (v) => !(v && v.length < 8) || "Password must be atleast  8 characters",
    ],

    form: new Form(
      {
        dialog: false,
        fname: "",
        mname: "",
        signature: "",
        lname: "",
        primary_mobile: "",
        email: "",
        password: "",
        pcity_id: "",
        pstate_id: "",
        pdistrict_id: "",
        tstate_id: "",
        tdistrict_id: "",
        pstreet: "",
        tcity_id: "",
        tstreet: "",
        secondary_mobile: "",
        residence: "",
        pan_no: "",
        gender: "",
        dob: "",
        blood_group: "",
        name: "",
        occupation: "",
        religion: "",
        ethnicity: "",
        profile_image: "",
        pward_no: "",
        roleId: "",
        departments: "",
        tward_no: "",
        image: {},
        join_date: "",
        primary_role_id: "",
      },
      "/api/user"
    ),
    imagePicker: {
      name: null,
      image: null,
      url: null,
    },
    staff: {
      validation: {
        valid: false,
        fnameRule: [(v) => !!v || "First name is required!"],
        lnameRule: [(v) => !!v || "Last name is required!"],

        usernameRule: [(v) => !!v || "Username is required!"],
        dobRule: [(v) => !!v || "Birth Date is required!"],
        primaryMobileRule: [
          (v) => !v || v.length === 10 || "Phone must be 10 digits",
        ],
        passwordRule: [
          (v) => !!v || "Password is required",
          (v) =>
            !(v && v.length < 8) || "Password must be atleast  8 characters",
        ],
        genderRule: [(v) => !!v || "Gender is required!"],
      },
      addressInfo: {
        data: {
          permanent_state_id: "",
          permanent_city_id: "",
          permanent_district_id: "",
          permanent_address_line: "",
          permanent_ward_no: "",
          temporary_state_id: "",
          temporary_city_id: "",
          temporary_district_id: "",
          temporary_address_line: "",
          temporary_ward_no: "",
        },
        validation: {
          valid: false,
          permanent_state_id_rule: [(v) => !!v || "Please select state!"],
          temporary_state_id_rule: [(v) => !!v || "Please select state!"],
          permanent_ward_no: [(v) => !!v || "Ward no required!"],
          role: [(v) => !!v || "You must specify a role!"],
          permanent_address_line_rule: [(v) => !!v || "Address is required!"],
          temporary_address_line_rule: [(v) => !!v || "Address is required!"],
          permanent_district_id_rule: [(v) => !!v || "Please select district!"],
          temporary_district_id_rule: [(v) => !!v || "Please select district!"],
          permanent_city_id_rule: [
            (v) => !!v || "Please select municipality/city!",
          ],
          temporary_city_id_rule: [
            (v) => !!v || "Please select municipality/city!",
          ],
          temporary_ward_no: [(v) => !!v || "Address is required!"],
        },
      },
    },
    search: "",
    statesData,
    districtDataData,
    cityData,
    headers: [
      { text: "#", align: "left", value: "sno", width: 5, sortable: false },
      { text: "Name", align: "left", value: "fname" },
      { text: "Contact", align: "left", value: "contact", sortable: false },
      { text: "Associated at", align: "left", value: "created_at" },
      { text: "Action", align: "right", sortable: false },
    ],
    updateState: false,
    chartData: [],
    chartOptions: {
      title: {
        text: "Grade-Wise Students",
        align: "center",
      },
      legend: {
        show: false,
        floating: true,
      },
      labels: [],
    },
    blood: ["AB+", "AB-", "A+", "A-", "B+", "B-", "O+", "O-"],
    religions: ["Hindu", "Muslim", "Buddhist", "Christian", "Other"],
    occupations: [
      "Agriculture",
      "Businessman",
      "Driving",
      "Engineer",
      "Foreign Employment",
      "Government Service",
      "Health Services",
      "Housewife",
      "Teacher",
      "Software Developer",
      "Other",
    ],
    select: "",
    items: ["Programming", "Design", "Vue", "Vuetify"],
    same_address: false,
    p_show: false,
    states: [],
    permanent_districts: [],
    permanent_cities: [],
    temporary_districts: [],
    temporary_cities: [],
    ward_count_permanent: [],
    ward_count_temporary: [],
    ethnicities: [
      "Brahmin",
      "Chhetri",
      "Janajati",
      "Dalit",
      "Muslim",
      "Newari",
      "Madhesi",
      "Other",
    ],
    gender: ["Male", "Female", "Others"],
    roles: [],
    departments: [],
    role: "",
    department: "",
    roleLoading: false,
    departmentLoading: false,
    selectedRole: [],
    selectedDepartment: [],
    imgURL: "",
    resultURL: "",
  }),
  watch: {
    pagination: function() {
      this.get();
    },
    "form.fname": {
      handler(name) {
        this.form.fname = this.capitalizeString(name);
      },
    },
    "form.mname": {
      handler(name) {
        this.form.mname = this.capitalizeString(name);
      },
    },
    "form.lname": {
      handler(name) {
        this.form.lname = this.capitalizeString(name);
      },
    },
    "staff.addressInfo.data.permanent_address_line": {
      handler(name) {
        this.staff.addressInfo.data.permanent_address_line = this.capitalizeString(
          name
        );
      },
    },

    "staff.addressInfo.data.temporary_address_line": {
      handler(name) {
        this.staff.addressInfo.data.temporary_address_line = this.capitalizeString(
          name
        );
      },
    },
    "staff.addressInfo.data.permanent_state_id": {
      handler(v) {
        this.getDistricts("permanent", v);
      },
    },
    "staff.addressInfo.data.permanent_district_id": {
      handler(v) {
        this.getCities("permanent", v);
      },
    },
    "staff.addressInfo.data.permanent_city_id": {
      handler(v) {
        this.getWardCount("permament");
      },
    },
    "staff.addressInfo.data.temporary_state_id": {
      handler(v) {
        this.getTemporaryDistricts("temporary", v);
      },
    },
    "staff.addressInfo.data.temporary_district_id": {
      handler(v) {
        this.getTemporaryCities("temporary", v);
      },
    },
    "staff.addressInfo.data.temporary_city_id": {
      handler(v) {
        this.getWardCount("temporary");
      },
    },
    same_address: {
      handler(v) {
        this.copyAddress(v);
      },
    },
    "members.staff.filter.role": {
      handler(v) {
        this.get();
      },
    },
  },
  mounted() {
    this.getStates();
    this.fetchRoles();
    this.fetchDepartments();
  },
  computed: {
    ...mapState(["members"]),
    dynamicColumns() {
      let columns = [];
      this.downloadColumns.map((item) => {
        if (item.toAdmin === true && this.$auth.isAdmin()) {
          columns.push(item);
        } else if (!item.toAdmin) {
          columns.push(item);
        }
      });
      return columns;
    },
  },
  methods: {
    validateForm() {
      if (this.$refs.staffInfoForm.validate()) {
        this.save();
      }
    },
    cropImage(data) {
      this.cropperImg.cropImg = data.image;
      this.cropperImg.uploadCropped = data.cropped;

      return;

      // get image data for post processing, e.g. upload or setting image src
      const canvas =
        type === "image"
          ? this.$refs.cropper.getCroppedCanvas()
          : this.$refs.cropper_signature.getCroppedCanvas();
      let imageType = "";

      if (type === "image") {
        this.cropperImg.cropImg = canvas.toDataURL();
        // imageType = this.cropperImg.cropImg.split(';')[0].split(':')[1];
        imageType = "image/jpeg";
      } else {
        this.cropperSignature.cropImg = canvas.toDataURL();
        // imageType = this.cropperSignature.cropImg.split(';')[0].split(':')[1];
        imageType = "image/png";
      }

      let vm = this;
      canvas.toBlob(function(blob) {
        const imageCompressor = new ImageCompressor();
        imageCompressor.compress(blob, { quality: 0.3 }).then((result) => {
          result.name = "image";
          if (type === "image") {
            vm.cropperImg.uploadCropped = result;
            vm.cropperImg.cropDialog = false;
          } else {
            vm.cropperSignature.uploadCropped = result;
            vm.cropperSignature.cropDialog = false;
          }
        });
      }, imageType);
    },
    searchMember: function(v) {
      if (v.length > 2 || !v.length) this.get();
    },
    getWardCount(type) {
      let $this = this;
      let totalWards = [];
      if (type === "permament") {
        let city = this.cityData.filter(function(city, key) {
          return $this.staff.addressInfo.data.permanent_city_id === city.id;
        });
        this.ward_count_permanent = [];
        // this.staff.addressInfo.data.permanent_ward_no = '';
        if (city.length) {
          city = city[0];
          let totalNumberOfWards = parseInt(city.ward_count);
          for (let i = 1; i <= totalNumberOfWards; i++) {
            totalWards.push(i);
          }
        }
        this.ward_count_permanent = totalWards;
      } else {
        let city = this.cityData.filter(function(city, key) {
          return $this.staff.addressInfo.data.temporary_city_id === city.id;
        });
        this.ward_count_temporary = [];
        // this.staff.addressInfo.data.temporary_ward_no = '';
        if (city.length) {
          city = city[0];
          let totalNumberOfWards = parseInt(city.ward_count);
          for (let i = 1; i <= totalNumberOfWards; i++) {
            totalWards.push(i);
          }
        }
        this.ward_count_temporary = totalWards;
      }
    },
    get(params) {
      let extraParams =
        "search=" +
        this.search.toString() +
        "&roleId=" +
        this.members.staff.filter.role.toString();
      let query = [null, undefined].includes(params)
        ? this.queryString(extraParams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
    save() {
      // if (this.$refs.staffInfoForm.validate()) {
      // this.form.dob = this.form.dob.dateForm();
      // this.form.join_date = this.form.join_date.dateForm();
      const { data } = this.staff;
      this.form.roleId = getPropertyFromArrayObject(
        "id",
        this.selectedRole
      ).join(",");
      this.form.departments = getPropertyFromArrayObject(
        "value",
        this.selectedDepartment
      ).join(",");
      this.form.pcity_id = this.staff.addressInfo.data.permanent_city_id;
      this.form.pdistrict_id = this.staff.addressInfo.data.permanent_district_id;
      this.form.pstate_id = this.staff.addressInfo.data.permanent_state_id;
      this.form.tcity_id = this.staff.addressInfo.data.temporary_city_id;
      this.form.pstreet = this.staff.addressInfo.data.permanent_address_line;
      this.form.tstreet = this.staff.addressInfo.data.temporary_address_line;
      this.form.pward_no = this.staff.addressInfo.data.permanent_ward_no;
      this.form.tward_no = this.staff.addressInfo.data.temporary_ward_no;
      this.form.tdistrict_id = this.staff.addressInfo.data.temporary_district_id;
      this.form.tstate_id = this.staff.addressInfo.data.temporary_state_id;
      if (!this.form.email) this.form.email = "";
      if (!this.form.primary_mobile) this.form.primary_mobile = "";

      //Check if a file was choosen.
      if (Object.keys(this.cropperImg.uploadCropped).length > 0) {
        this.form.image = this.cropperImg.uploadCropped;
      } else {
        delete this.form.image;
      }

      const savedPromise = this.updateState
        ? this.form.put(this.form.id)
        : this.form.store();
      savedPromise.then((res) => {
        this.resetForm();
      });
      // } else {
      //     this.$events.fire('notification', {message: 'Validation Failed', status: 'error'});
      // }
    },
    getStates(type) {
      this.states = this.statesData.map((item) => {
        return { text: item.name, value: item.id };
      });
    },
    getDistricts(type, id) {
      this.permanent_districts = this.districtDataData
        .filter(
          (item) =>
            item.state_id === this.staff.addressInfo.data.permanent_state_id
        )
        .map((item) => {
          return { value: item.id, text: item.name };
        });
      this.permanent_cities = [];

      if (type === "permanent") {
        // this.staff.addressInfo.data.permanent_district_id = '';
        // this.staff.addressInfo.data.permanent_city_id = '';
        // this.staff.addressInfo.data.permanent_address_line = '';
      }
    },
    getTemporaryDistricts(type, id) {
      this.temporary_districts = this.districtDataData
        .filter(
          (item) =>
            item.state_id === this.staff.addressInfo.data.temporary_state_id
        )
        .map((item) => {
          return { value: item.id, text: item.name };
        });
    },
    getCities(type, id) {
      this.permanent_cities = this.cityData
        .filter(
          (item) =>
            item.district_id ===
            this.staff.addressInfo.data.permanent_district_id
        )
        .map((item) => {
          return { value: item.id, text: item.name };
        });
      if (type === "permanent") {
        // this.staff.addressInfo.data.permanent_city_id = '';
        // this.staff.addressInfo.data.permanent_address_line = '';
      }
      this.temporary_districts = this.permanent_districts;
      this.temporary_cities = this.permanent_cities;
    },

    getTemporaryCities(type, id) {
      this.temporary_cities = this.cityData
        .filter(
          (item) =>
            item.district_id ===
            this.staff.addressInfo.data.temporary_district_id
        )
        .map((item) => {
          return { value: item.id, text: item.name };
        });
    },

    fetchRoles() {
      this.roleLoading = true;
      this.$rest
        .get("/api/roles?rowsPerPage=50&sortBy=name&slim=true")
        .then(({ data }) => {
          if (data.data.length) {
            this.roles = data.data;
            // console.log(data.data);
            // this.roles.push({id:1015, name:'Class Teacher'})
          }
        })
        .finally(() => {
          this.roleLoading = false;
        });
    },
    fetchDepartments() {
      this.roleLoading = true;
      this.$rest
        .get("/api/department?rowsPerPage=50&sortBy=show_order")
        .then(({ data }) => {
          if (data.data.length) {
            this.departments = data.data.map((res) => {
              return { text: res.title, value: res.id };
            });
            // this.departments = data.data.map(res => {
            //     res.name = res.title;
            // });

            // console.log(data.data);
            // this.roles.push({id:1015, name:'Class Teacher'})
          }
        })
        .finally(() => {
          this.departmentLoading = false;
        });
    },
    copyAddress(status) {
      if (status) {
        if (
          this.staff.addressInfo.data.permanent_address_line &&
          this.staff.addressInfo.data.permanent_city_id
        ) {
          let temp = {
            state: this.staff.addressInfo.data.permanent_state_id,
            district: this.staff.addressInfo.data.permanent_district_id,
            city: this.staff.addressInfo.data.permanent_city_id,
            address: this.staff.addressInfo.data.permanent_address_line,
            ward_no: this.staff.addressInfo.data.permanent_ward_no,
          };
          this.ward_count_temporary = this.ward_count_permanent;
          let data = JSON.parse(JSON.stringify(temp));
          this.$set(
            this.staff.addressInfo.data,
            "temporary_state_id",
            data.state
          );
          this.$set(
            this.staff.addressInfo.data,
            "temporary_district_id",
            data.district
          );
          this.$set(
            this.staff.addressInfo.data,
            "temporary_city_id",
            data.city
          );
          this.$set(
            this.staff.addressInfo.data,
            "temporary_address_line",
            data.address
          );
          this.$set(
            this.staff.addressInfo.data,
            "temporary_ward_no",
            data.ward_no
          );
        }
      }
    },
    onFilePicked(e, type = "image") {
      const file = e.target.files[0];
      if (!file.type.includes("image/")) {
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (type === "image") {
            this.cropperImg.imgSrc = event.target.result;
            this.cropperImg.name = file.name;
            this.cropperImg.cropDialog = true;
            if (this.$refs.cropper) {
              this.$refs.cropper.replace(event.target.result);
            }
          } else {
            this.cropperSignature.name = file.name;
            this.form.signature = this.$refs.signature.files[0];
            this.cropperSignature.imgSrc = event.target.result;
          }
        };
        reader.readAsDataURL(file);
      }
    },
    resetImagePicker(type) {
      this.imagePicker = {
        url: undefined,
        image: undefined,
        name: undefined,
      };
      // this.$refs.image.value = "";
      this.cropperImg.cropImg = "";
      this.cropperImg.name = "";
    },
    resetForm() {
      this.selectedRole = "";
      this.staff.addressInfo.data.temporary_state_id = "";
      this.staff.addressInfo.data.temporary_address_line = "";
      this.staff.addressInfo.data.temporary_district_id = "";
      this.staff.addressInfo.data.temporary_city_id = "";
      this.staff.addressInfo.data.temporary_ward_no = "";
      this.staff.addressInfo.data.permanent_ward_no = "";
      this.staff.addressInfo.data.permanent_city_id = "";
      this.staff.addressInfo.data.permanent_district_id = "";
      this.staff.addressInfo.data.permanent_state_id = "";
      this.staff.addressInfo.data.permanent_address_line = "";
      this.cropperImg.cropImg = "";
      this.cropperImg.name = "";
      this.cropperImg.uploadCropped = {};
      this.cropperSignature.imgSrc = "";
      this.cropperSignature.name = "";
      this.cropperSignature.uploadCropped = {};
      this.$refs.staffInfoForm.reset();
      this.updateState = false;
    },
    /**
     * Image Upload Handler
     */
    onPickFile(type = "image") {
      if (type === "signature") {
        this.$refs.signature.click();
      } else {
        this.$refs.image.click();
      }
    },
    update(items) {
      this.form.edit(items);
      // console.log(items);
      this.selectedDepartment = items.departments.map((res) => {
        return { value: res.id, text: res.title };
      });
      this.updateState = true;
      const $this = this;
      this.same_address = false;
      Object.keys(items).map(function(item) {
        if (item === "roles") {
          $this.selectedRole = items[item];
        } else if (item === "tstate_id") {
          $this.staff.addressInfo.data.temporary_state_id = items[item];
        } else if (item === "tdistrict_id") {
          $this.staff.addressInfo.data.temporary_district_id = parseInt(
            items[item]
          );
        } else if (item === "tcity_id") {
          $this.staff.addressInfo.data.temporary_city_id = parseInt(
            items[item]
          );
        } else if (item === "tward_no") {
          $this.staff.addressInfo.data.temporary_ward_no = parseInt(
            items[item]
          );
        } else if (item === "tward_no") {
          $this.staff.addressInfo.data.temporary_ward_no = parseInt(
            items[item]
          );
        } else if (item === "tstreet") {
          $this.staff.addressInfo.data.temporary_address_line = items[item];
        } else if (item === "pstate_id") {
          //console.log(item, items[item]);
          $this.staff.addressInfo.data.permanent_state_id = items[item];
        } else if (item === "pdistrict_id") {
          //console.log(item, items[item]);
          $this.staff.addressInfo.data.permanent_district_id = parseInt(
            items[item]
          );
        } else if (item === "pcity_id") {
          //console.log(item, parseInt(items[item]));
          $this.staff.addressInfo.data.permanent_city_id = parseInt(
            items[item]
          );
        } else if (item === "pward_no") {
          //console.log(item);
          $this.staff.addressInfo.data.permanent_ward_no = parseInt(
            items[item]
          );
        } else if (item === "pstreet") {
          $this.staff.addressInfo.data.permanent_address_line = items[item];
        } else if (item === "image") {
          $this.cropperImg.cropImg = items[item];
        } else if (item === "signature_image") {
          $this.cropperSignature.imgSrc = items[item];
        }
      });
    },
    markAsPrimaryRole(role) {
      if (role && Object.keys(role).length > 0)
        this.form.primary_role_id = role.id;
    },
    removeRole(role) {
      if (role && Object.keys(role).length > 0) {
        this.selectedRole.splice(this.selectedRole.indexOf(role), 1);
      }
    },
    removeDepartment(department) {
      if (department && Object.keys(department).length > 0) {
        this.selectedRole.splice(this.selectedRole.indexOf(department), 1);
      }
    },
    capitalizeString(string) {
      if (string === undefined || string === null) return "";
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    downloadStaff({ type, columns, columnString }) {
      let role = "";
      if (this.members.staff.filter.role)
        this.roles.map((item) => {
          if (item.id === this.members.staff.filter.role) {
            role = item.slug;
          }
        });
      this.$rest.get("/api/download/staff").then(({ data }) => {
        let url =
          data.data.download_url +
          `?columns=${columnString}` +
          (role ? "?role=" + role : "");
        window.open(url);
      });
    },

    roleChanged(item) {
      this.selectedRole = this.selectedRole.filter(function(item) {
        return typeof item === "object";
      });
      // document.querySelector('input[role=combobox]').setAttribute('value', 'testing...');
    },
    departmentChanged(item) {
      this.selectedDepartment = this.selectedDepartment.filter(function(item) {
        return typeof item === "object";
      });
      // document.querySelector('input[role=combobox]').setAttribute('value', 'testing...');
    },
  },
};
</script>

<style lang="scss">
.staff {
  .v-list__tile {
    padding: 0 !important;
  }

  .v-list__tile__title {
    height: 15px;
    line-height: 15px;
  }
}

.user_add_form {
  .v-chip,
  .v-chip .v-chip__content {
    border-radius: 2px;
    color: white;
  }
}

.v-timeline--dense:before {
  left: 18px !important;
}

.v-timeline-item__dot.v-timeline-item__dot--small {
  margin-left: 0px !important;
}

.first-f {
  background-color: #f44336 !important;
  border-color: #f44336 !important;
}

.second-s {
  background-color: rgba(255, 28, 26, 0.59) !important;
  border-color: rgba(255, 28, 26, 0.59) !important;
}

.third-t {
  background-color: yellow !important;
  border-color: yellow !important;
}

.fourth-f {
  background-color: #e48d0d !important;
  border-color: #e48d0d !important;
}

.fifth-fi {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}
</style>
